<template>
  <ion-page><cyan-page-wrapper title="Elegir provincia" :burst="true" backRoute="/partyLevels" nav1="MODO PARTIDO">
    <mapa-sv nextRoute="/pickMpio/" :key="'mapaPickMpio'"/>
    <territory-picker nextRoute="/pickMpio/" />
  </cyan-page-wrapper></ion-page>
</template>

<script lang="ts">
import CyanPageWrapper, { defineComponent, IonPage } from '@/components/CyanPageWrapper.vue'
import TerritoryPicker from '@/components/TerritoryPicker.vue';
import MapaSv from '@/components/MapaSv.vue';

export default defineComponent({
  name: "PickDeptoMpio",
  components: {
    TerritoryPicker,
    IonPage,
    MapaSv,
    CyanPageWrapper
  },
  computed: {},
  methods: {}
});
</script>
